// Resources
import LOCALES from "@/i18n/locals.json";
import PAGES from "@/i18n/pages.json";
// Content
import globalContent from "@/i18n/json/global";
// import navigationContent from "@/page-content/navigation";
import footerContent from "@/i18n/json/footer";
import homeContent from "@/i18n/json/home";
import pageNotFoundContent from "@/i18n/json/404";
import careersContent from "@/i18n/json/careers";
import contactContent from "@/i18n/json/contact";
import pricingContent from "@/i18n/json/pricing";
import whoWeAreContent from "@/i18n/json/who-we-are";
import projectsAndPartners from "@/i18n/json/projects-partners";
import WebinarsContent from "@/i18n/json/webinars";
import terms from "@/i18n/json/terms";
import forms from "@/i18n/json/forms";
import support from "@/i18n/json/support";
import awards from "@/i18n/json/awards";
import MediaContent from "@/i18n/json/media";
import FreeResources from "@/i18n/json/free-resources";
import shippingAndReturns from "@/i18n/json/shipping-and-returns";
import mhealthStudio from "@/i18n/json/mhealth";
import hearOAE from "@/i18n/json/hearoae";

interface IResources {
  [key: string]: {
    translation: {};
  };
}

interface IObject {
  [key: string]: string | {};
}

interface IDictionary {
  [key: string]: string;
}

const locales: IDictionary = LOCALES; // Making the json object type safe
const pageNames: IDictionary = PAGES; // Making the json object type safe

const languages = Object.keys(locales).map((item) => {
  // Creating languages array
  return locales[item];
});

const pages = Object.keys(pageNames).map((item) => {
  // Creating pages array
  return pageNames[item];
});

// Helper function to pass to the i18next resources section
export const getResources = () => {
  const resources: IResources = {};
  // Looping through the languages to create a resource for each language
  languages.map((lang) => {
    const page: IObject = {};
    // Looping through all the available pages to create a resource for each page using the data files
    pages.map((pageName) => {
      let pageContent = {};
      // Assigning the correct data file to the correct page
      switch (pageName) {
        case "index":
          pageContent = homeContent[lang];
          break;
        case "pricing":
          pageContent = pricingContent[lang];
          break;
        case "who-we-are":
          pageContent = whoWeAreContent[lang];
          break;
        case "contact":
          pageContent = contactContent[lang];
          break;
        case "projects-partners":
          pageContent = projectsAndPartners[lang];
          break;
        case "webinars":
          pageContent = WebinarsContent[lang];
          break;
        case "terms":
          pageContent = terms[lang];
          break;
        case "support":
          pageContent = support[lang];
          break;
        case "awards":
          pageContent = awards[lang];
          break;
        case "media":
          pageContent = MediaContent[lang];
          break;
        case "free-resources":
          pageContent = FreeResources[lang];
          break;
        case "shipping":
          pageContent = shippingAndReturns[lang];
          break;
        case "mhealth":
          pageContent = mhealthStudio[lang];
          break;
        case "hearoae":
          pageContent = hearOAE[lang];
          break;
        case "404":
          pageContent = pageNotFoundContent[lang];
          break;
      }
      // Setting the page content in the page object
      page[pageName] = pageContent;
    });
    // Adding the page resources to be translated to the correct language
    // Add Navigation on a later state : navigation: { ...navigationContent[lang] }
    resources[lang] = {
      translation: {
        ...page,
        global: { ...globalContent[lang] },
        forms: { ...forms[lang] },
        footer: { ...footerContent[lang] },
      },
    };
  });

  return resources;
};

export default getResources;
